import React from 'react';
import './AboutUs.css';
import { useInView } from 'react-intersection-observer';
import ScrollToTopScroller from '../elements/ScrollerToTop';
import Footer from '../components/Footer';

const AboutUs = () => {
  const { ref: logoRef, inView: logoInView } = useInView({ triggerOnce: true });
  const { ref: pokeRef, inView: pokeInView } = useInView({ triggerOnce: true });
  const { ref: donburiRef, inView: donburiInView } = useInView({ triggerOnce: true });
  const { ref: ramenRef, inView: ramenInView } = useInView({ triggerOnce: true });

  return (
    <>
    <div className="about-us" style={{ marginTop: '120px' }}>
      <div className="container" style={{ maxWidth: '900px', margin: '0 auto' }}>
        
        {/* Header Section with Logo on Left */}
        <div className="row mb-5 align-items-center">
          <div className="col-lg-6">
            <img
              src="https://static.wixstatic.com/media/563bec_64204165834a44758e149ebcb027548e~mv2.png/v1/fill/w_284,h_130,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/mugi%2520logo%2520large_edited.png"
              alt="MUGI Ramen & Poke"
              className="logo img-fluid"
            />
          </div>
          <div className={`col-lg-6 slide-in-right ${logoInView ? 'show' : ''}`} ref={logoRef}>
            <h1>MUGI RAMEN & POKE</h1>
            <p>
              Mugi Food&Co is a local instant food company focusing on providing fresh, delicious, and healthy food to everyone. 
              Also, if you never try poke, ramen, and Japanese-style rice bowls, you need to come to us!
            </p>
          </div>
        </div>

        {/* Poke Bowl Section - Image on Right */}
        <div className="row mb-5 align-items-center">
          <div className="col-lg-6 order-lg-2">
            <img
              src="https://static.wixstatic.com/media/563bec_4021054806404a679febb17e2293c1e6~mv2.jpg/v1/crop/x_232,y_0,w_1584,h_1365/fill/w_300,h_256,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_8348.jpg"
              alt="Poke Bowl"
              className="img-fluid rounded"
            />
          </div>
          <div className={`col-lg-6 order-lg-1 slide-in-left ${pokeInView ? 'show' : ''}`} ref={pokeRef}>
            <h3>Our Poke Bowls</h3>
            <p>Our Poke Bowls are made by real fish. All the fishes are delivered by a Japanese food company.</p>
            <p>And here is the ordinary of how to order a poke bowl:</p>
            <ul>
              <li>Step 1: Choose a base (White rice, Brown rice, or Green).</li>
              <li>Step 2: Choose your protein (Fish like Salmon or meat like Chicken).</li>
              <li>Step 3: Mix in with green onion or Masago.</li>
              <li>Step 4: Choose your sauce (We have so many sauces, <a href="#">CHECK OUR MENU!</a>).</li>
              <li>Step 5: Choose your topping. We have salad topping, vegetable topping, and dry topping.</li>
            </ul>
          </div>
        </div>

        {/* Donburi Section - Image on Left */}
        <div className="row mb-5 align-items-center">
          <div className="col-lg-6">
            <img
              src="https://static.wixstatic.com/media/563bec_8a85cb0aac374001b4fac5366cb5acb2~mv2.jpg/v1/fill/w_288,h_186,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_8212.jpg"
              alt="Donburi"
              className="img-fluid rounded"
            />
          </div>
          <div className={`col-lg-6 slide-in-right ${donburiInView ? 'show' : ''}`} ref={donburiRef}>
            <h3>Japanese Style Rice Bowl (Donburi)</h3>
            <p>
              Japanese style rice bowl is also known as Donburi. Donburi (丼, literally "bowl", also abbreviated to "don").
              Don is a Japanese "rice bowl dish" consisting of fish, meat, vegetables, or other ingredients simmered together and served over rice.
            </p>
            <p>Donburi is sometimes called "sweetened" or "savory stews on rice".</p>
          </div>
        </div>

        {/* Ramen Section - Image on Right */}
        <div className="row mb-5 align-items-center">
          <div className="col-lg-6 order-lg-2">
            <img
              src="https://static.wixstatic.com/media/563bec_c072be65918547ccbab7b30295b79867~mv2.jpg/v1/crop/x_232,y_0,w_1584,h_1365/fill/w_574,h_492,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_8330.jpg"
              alt="Ramen"
              className="img-fluid rounded"
            />
          </div>
          <div className={`col-lg-6 order-lg-1 slide-in-left ${ramenInView ? 'show' : ''}`} ref={ramenRef}>
            <h3>Ramen</h3>
            <p>
              Ramen (/ˈrɑːmən/) (拉麺, ラーメン) is a Japanese dish with a translation of "pulled noodles". It consists of Chinese wheat noodles served
              in a meat or (occasionally) fish-based broth, often flavored with soy sauce or miso, and uses toppings such as sliced pork (叉焼, chāshū),
              nori (dried seaweed), menma, and scallions.
            </p>
          </div>
        </div>
      </div>
      <ScrollToTopScroller />
      
      <Footer />
    </div>
    </>
  );
};

export default AboutUs;
