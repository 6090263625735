import { useNavigate } from "react-router-dom";
import './css/Hero2.css'
import hero from '../asset/Images/hero1.JPG'

export default function HeroSection2() {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  return (
<div class="hero-section-2" >
  <div class="overlay">
    <p class="hero-subtitle">ENJOY</p>
    <h1 class="hero-title">RAMEN</h1>
    <button class="order-button" onClick={() => navigate('/order')}>ORDER ONLINE</button>
  </div>
</div>

  );
}
