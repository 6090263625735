import React from 'react';
import logo from '../elements/logo.png'

function LoadingOverlay({ isVisible }) {
  return (
    <div className={`loading-overlay ${!isVisible ? 'fade-out' : ''}`}>
      <img
        src={logo}
        alt="Loading..."
        className="loading-logo"
      />
    </div>
  );
}

export default LoadingOverlay;
