import { useNavigate } from 'react-router-dom';
import './css/Banner.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function BannerSection() {
  const navigate = useNavigate();

  return (
<div class="full-width-section">
  <div class="left-side">
    <img
      src="https://static.wixstatic.com/media/563bec_677546bbdea2428580202f509a85dbc5~mv2.jpg/v1/fill/w_730,h_651,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/563bec_677546bbdea2428580202f509a85dbc5~mv2.jpg"
      alt="Poke Bowl"
      class="poke-image"
    />
  </div>
  <div class="right-side">
    <h2 class="poke-title">POKE</h2>
    <h4 class="poke-subtitle">Taste the Fresh!</h4>
    <p class="poke-description">
      Poke /poʊˈkeɪ/ (Hawaiian for "to slice" or "cut crosswise into pieces";
      sometimes stylized Poké to aid pronunciation, also called Poké Bowl) is
      diced raw fish served either as an appetizer or as a main course and is
      one of the main dishes of Native Hawaiian cuisine.
    </p>
    <button class="menu-button" onClick={() => navigate('/menu')}>MENU</button>
  </div>
</div>

  );
}
