import { useNavigate } from "react-router-dom";
import './css/Hero.css'
import heroimage from '../elements/hero1.jpg'

export default function HeroSection() {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  return (
<div class="hero-section">
  <div class="overlay">
    <img
      src="https://static.wixstatic.com/media/563bec_5f6a181cd32f444482c29950558c1118~mv2.png/v1/crop/x_257,y_465,w_1727,h_777/fill/w_662,h_294,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/mugi%20logo%20white%20side.png"
      alt="Mugi Ramen & Poke Logo"
      class="hero-logo"
      style={{opacity:0.6}}

    />
    <div />
    
    <button class="menu-button-1" onClick={() => navigate('/order')}>Order Now</button>
  </div>
</div>
  );
}
