import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { createCheckoutSessionThunk } from "../store/stripe";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeCheckoutForm.js";




const stripePromise = loadStripe("pk_live_51N1jsSIzDtG53Sp0IYroac8Yq8aSbX7PQgISERDGaQNviBMnz4SGJ4RhjZws64QrELl0aJoWqtyYe5W3Ud6bqhmV00sR5crhK1", { 
    stripeAccount:'acct_1QKSFYCfFFR4cL4Q' 
  });


const CheckoutApp = ({tip, restaurantId, name, phone, cartItems, coupon, address, time}) => {
    const dispatch = useDispatch();
    const clientSecret = useSelector((state) => state?.stripe?.data?.clientSecret);
    
    useEffect(() => {
      dispatch(createCheckoutSessionThunk(tip, restaurantId, cartItems, coupon, address))
  
    }, [dispatch]);
  
    const appearance = {
      theme: 'flat',
    };
    const options = {
      clientSecret,
      appearance,
    };
  
  
    return (
      <div >
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm tip={tip} name={name} phone={phone} cartItems={cartItems} coupon={coupon}  address={address} restaurantId={restaurantId} time={time}/>
          </Elements>
        )}
      </div>
    );
  }

export default CheckoutApp;
