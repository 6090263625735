import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt, FaClock, FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div style={{ marginTop: '150px' }}>
      <Container className="text-center">
        <h1 style={{ color: '#a6e01e', marginBottom: '30px' }}>Contact Us</h1>
        
        {/* Location and Contact Info */}
        <Row className="justify-content-center mb-5">
          <Col md={6} className="text-center">
            <h4 style={{ color: '#a6e01e' }}>
              <FaMapMarkerAlt /> Our Location
            </h4>
            <p>Mugi Ramen & Poke</p>
            <p>1181 W 120th Ave #100,
            Westminster, CO 80234</p>
            <p><FaPhoneAlt /> (303) 280-4565
</p>
          </Col>
        </Row>

        {/* Opening Hours */}
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <h4 style={{ color: '#a6e01e' }}>
              <FaClock /> Opening Times
            </h4>
            <ul className="list-unstyled">
              <li>Hours:
Mon-Fri ... 11AM-9PM
< br />
Sat-Sun ... 12-9PM</li>
          
            </ul>
          </Col>
        </Row>


      </Container>

      {/* Custom Styles */}
      <style jsx>{`
        h1, h4 {
          font-weight: bold;
        }
        ul {
          padding-left: 0;
        }
        li {
          font-size: 1.1rem;
        }
      `}</style>
    </div>
  );
};

export default ContactUs;
