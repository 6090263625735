import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../elements/logo.png';
import { FaShoppingBag, FaUser, FaGlobe, FaCar } from 'react-icons/fa';
import './Header.css';


const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const SCROLL_UP_THRESHOLD = 1000;

  const handleNavClick = (path) => {
    navigate(path);
  
    // Close the menu if it's open
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');
  
    if (navbarToggler && navbarCollapse.classList.contains('show')) {
      navbarToggler.click(); // Toggles the collapse
    }
  }

  useEffect(() => {
    const categorySection = document.querySelector('.category-menu-wrapper');
    if (categorySection && location.pathname === '/order') {
      categorySection.style.top = isVisible ? '80px' : '0'; // Adjust the top value based on your header height
      categorySection.style.transition = 'top 0.5s';
    }
  }, [isVisible, location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/order') {
        const currentScrollY = window.scrollY;
        let distanceScrolled = 0;

        if (currentScrollY === 0) {
          setIsVisible(true);
        } else {
          const isScrollingDown = currentScrollY > lastScrollY;

          if (isScrollingDown) {
            setIsVisible(false);
          } else {
            distanceScrolled += lastScrollY - currentScrollY;
            if (distanceScrolled >= SCROLL_UP_THRESHOLD) {
              setIsVisible(true);
              distanceScrolled = 0;
            }
          }
        }

        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, location.pathname]);

  useEffect(() => {
    if (['/', '/checkout', '/menu', '/contact'].includes(location.pathname)) {
      setIsVisible(true);
    }
  }, [location.pathname]);

  return (
    <div className="container">
    <Navbar
      expand="lg"
      className={`header ${isVisible ? 'visible' : 'hidden'}`}
      fixed="top"
    >
      <Container className="d-flex header-container">
        <Navbar.Brand onClick={() => navigate('/')} className="header-logo">
          <img
            src={logo}
            alt="Logo"
            className="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-nav text-center">
            <Nav.Link onClick={() => handleNavClick('/')} className="nav-item">HOME</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/menu')} className="nav-item">MENU</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/order')} className="nav-item">ONLINE ORDER</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/about')} className="nav-item">ABOUT</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/contact')} className="nav-item">CONTACT</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/gallery')} className="nav-item">GALLERY</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
};

export default Header;
