export default function ScrollToTopScroller(){ 
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return ( 
        
        <div className="footer-strip">
        <button className="top-button" onClick={scrollToTop}>
          TOP
        </button>
      </div>

    )
}
