import { useNavigate } from "react-router-dom";
import './css/Hero3.css'
import hero from '../asset/Images/hero1.JPG'

export default function HeroSection3() {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  return (
<div class="hero-section-3" >
  <div class="overlay">
    <h1 class="hero-title">GALLERY
    </h1>
    <button class="order-button" onClick={() => navigate('/gallery')}>Enter</button>
  </div>
</div>

  );
}
