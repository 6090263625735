import { useNavigate } from 'react-router-dom';
import './css/Banner.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function BannerSection2() {
  const navigate = useNavigate();

  return (
<div class="full-width-section">
  <div class="right-side">
    <h2 class="poke-title">RICE BOWL</h2>
    <h4 class="poke-subtitle">Beef, Chicken or others
    </h4>
    <p class="poke-description">
    Whether you like beef, chicken or eel, we have them all!
    </p>
    <button class="menu-button" onClick={() => navigate('/about')}>READ MORE</button>
  </div>
  <div class="left-side-1">
    <img
      src="https://static.wixstatic.com/media/563bec_4c4c473110944699b1760ae2c6b81df9~mv2.jpg/v1/fill/w_693,h_650,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/563bec_4c4c473110944699b1760ae2c6b81df9~mv2.jpg"
      alt="Poke Bowl"
      class="poke-image"
    />
  </div>
</div>

  );
}
