import React from 'react';
import logo from '../asset/Images/logo.png';
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import './css/Footer.css';

const Footer = () => {


  return (
    <div className="footer">

      <div className="footer-content">
        <div className="location">
          <h3>Westminster Location</h3>
          <p>
            <strong>Address:</strong><br />
            1181 W 120th Ave #100,<br />
            Westminster, CO 80234
          </p>
          <p>
            <strong>Phone:</strong><br />
            (303) 280-4565
          </p>
          <p>
            <strong>Hours:</strong><br />
            Mon-Fri ... 11AM-9PM<br />
            Sat-Sun ... 12-9PM
          </p>
        </div>
 
      </div>
      <div className="footer-bottom" >
        <p>©2020 by Mile High Asian Media</p>
      </div>
    </div>
  );
};

export default Footer;
