import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext, useState } from 'react';
import { getRestaurantThunk } from './store/restaurant';
import Context from './Context';
import { Route, Routes } from 'react-router-dom';
import OnlineOrder from './Pages/OnlineOrder';
import CheckoutPage from './Pages/Checkout';
import ScrollToTop from './elements/ScrollToTop';
import OrderConfirmed from './Pages/OrderPlaced';
import Header from './elements/Header';
import Home from './Pages/Home';
import Receipt from './Pages/Receipt';
import Menu from './Pages/menu';
import ContactUs from './Pages/Contact';
import AboutUs from './Pages/About';
import ImageGallery from './Pages/Gallery';
import LoadingOverlay from './Pages/Loading'; // Add this import for LoadingOverlay

function App() {
  const dispatch = useDispatch();
  const restaurant = useSelector((state) => state.restaurants.restaurant);
  const { restaurantId } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true); // For fade-out effect

  useEffect(() => {
    if (restaurantId) {
      setIsLoading(true);
      const fetchRestaurantData = async () => {
        const fetchStart = Date.now();
        await dispatch(getRestaurantThunk(restaurantId));
        const fetchEnd = Date.now();
        const fetchDuration = fetchEnd - fetchStart;

        const minimumDuration = 2000;
        const remainingTime = minimumDuration - fetchDuration;

        if (remainingTime > 0) {
          setTimeout(() => setIsLoading(false), remainingTime);
        } else {
          setIsLoading(false);
        }
      };

      fetchRestaurantData();
    }
  }, [dispatch, restaurantId]);

  // Trigger fade-out effect
  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowOverlay(false), 1000); // 500ms for the fade-out transition
    }
  }, [isLoading]);

  return (
    <div className="App">
      {showOverlay && <LoadingOverlay isVisible={isLoading} />} {/* Show loading overlay while loading */}

      <Header />
      <ScrollToTop />

      <Routes>
        <Route exact path='/' element={<Home restaurant={restaurant} />} />
        <Route path="/order" element={<OnlineOrder restaurant={restaurant} />} /> 
        <Route path='/checkout' element={<CheckoutPage fee={restaurant?.Restaurant_Fees[0]} />} />
        <Route path='/orderconfirmed/:tip/:name/:phone/:coupon/:address/:time' element={<OrderConfirmed />} />
        <Route path='/order/:id/:restaurant_id' element={<Receipt />} />
        <Route path='/menu' element={<Menu restaurant={restaurant} />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/gallery' element={<ImageGallery />} />
      </Routes>
    </div>
  );
}

export default App;
