import React, { useRef } from 'react';
import './Gallery.css';
import Footer from '../components/Footer';

const Gallery = () => {
  const galleryRef = useRef(null);

  const scrollLeft = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({
        left: -300, // Adjust the scroll distance as needed
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({
        left: 300, // Adjust the scroll distance as needed
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
    <div className="gallery-wrapper">
      <button className="scroll-button left" onClick={scrollLeft}>
        &lt;
      </button>
      
      <div className="gallery-container" ref={galleryRef}>
        {/* Main Image Container Left */}
        <div className="main-image-container">
  <div className="main-image-container-top">
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_0cca0fbb63b94b47bc01778444576ddd~mv2.jpg/v1/fill/w_971,h_648,q_90/563bec_0cca0fbb63b94b47bc01778444576ddd~mv2.jpg" alt="" />
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_35588a5491b7475593100c5adbdbc1f1~mv2.jpg/v1/fill/w_319,h_213,q_90/563bec_35588a5491b7475593100c5adbdbc1f1~mv2.jpg" alt="" />
  </div>
  <div className="main-image-container-bottom">
    <img className="img-2" src="https://static.wixstatic.com/media/563bec_58f83f78d9c64272bdb30616c3026952~mv2.jpg/v1/fill/w_320,h_213,q_90/563bec_58f83f78d9c64272bdb30616c3026952~mv2.jpg" alt="" />
  </div>
</div>

{/* Middle Container */}
<div className="middle-container">
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_ae1870c78a094024a2fcddb0e135bd9d~mv2.jpg/v1/fill/w_644,h_430,q_90/563bec_ae1870c78a094024a2fcddb0e135bd9d~mv2.jpg" alt="" />
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_8ee623acbaa04e65a44c8f433e9c17d8~mv2.jpg/v1/fill/w_482,h_322,q_90/563bec_8ee623acbaa04e65a44c8f433e9c17d8~mv2.jpg" alt="" />
</div>

{/* Main Image Container Right */}
<div className="main-image-container">
  <div className="main-image-container-bottom">
    <img className="img-2" src="https://static.wixstatic.com/media/563bec_e9e5e2376517455fb90018e4bfdf083c~mv2.jpg/v1/fill/w_482,h_321,q_90/563bec_e9e5e2376517455fb90018e4bfdf083c~mv2.jpg" alt="" />
  </div>
  <div className="main-image-container-top">
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_3d7e7c91d543434e9c0a8ccf31548fbe~mv2.jpg/v1/fill/w_644,h_430,q_90/563bec_3d7e7c91d543434e9c0a8ccf31548fbe~mv2.jpg" alt="" />
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_e8a9dd54c94146e3b281674cb6f64789~mv2.jpg/v1/fill/w_319,h_213,q_90/563bec_e8a9dd54c94146e3b281674cb6f64789~mv2.jpg" alt="" />
  </div>
</div>

{/* Middle Container */}
<div className="middle-container">
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_1772dd3b71364655a0f92ea7acffa6bc~mv2.jpg/v1/fill/w_320,h_213,q_90/563bec_1772dd3b71364655a0f92ea7acffa6bc~mv2.jpg" alt="" />
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_2c0d9c6684d543c48a2d6d0deac921f3~mv2.jpg/v1/fill/w_319,h_213,q_90/563bec_2c0d9c6684d543c48a2d6d0deac921f3~mv2.jpg" alt="" />
</div>

<div className="main-image-container">
  <div className="main-image-container-top">
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_02e9f6a733db46b08140bcc388429a2e~mv2.jpg/v1/fill/w_320,h_213,q_90/563bec_02e9f6a733db46b08140bcc388429a2e~mv2.jpg" alt="" />
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_c050513a840645828d53e6a59cebca83~mv2.jpg/v1/fill/w_644,h_430,q_90/563bec_c050513a840645828d53e6a59cebca83~mv2.jpg" alt="" />
  </div>
  <div className="main-image-container-bottom">
    <img className="img-2" src="https://static.wixstatic.com/media/563bec_66bd8e3654de43aca773551c5e17d1f2~mv2.jpg/v1/fill/w_482,h_322,q_90/563bec_66bd8e3654de43aca773551c5e17d1f2~mv2.jpg" alt="" />
  </div>
</div>

<div className="main-image-container">
  <div className="main-image-container-bottom">
    <img className="img-2" src="https://static.wixstatic.com/media/563bec_fa49cb28c1c141a1bda3ff525abc9b4c~mv2.jpg/v1/fill/w_482,h_321,q_90/563bec_fa49cb28c1c141a1bda3ff525abc9b4c~mv2.jpg" alt="" />
  </div>
  <div className="main-image-container-top">
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_c5f4202dd0df47778b817a07e7d8720d~mv2.jpg/v1/fill/w_644,h_430,q_90/563bec_c5f4202dd0df47778b817a07e7d8720d~mv2.jpg" alt="" />
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_c6afb1bd1b324c1fb2531cd1ac89b424~mv2.jpg/v1/fill/w_319,h_213,q_90/563bec_c6afb1bd1b324c1fb2531cd1ac89b424~mv2.jpg" alt="" />
  </div>
</div>

<div className="middle-container">
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_947752d67dc04ddd99a656f05a4131f8~mv2.jpg/v1/fill/w_320,h_213,q_90/563bec_947752d67dc04ddd99a656f05a4131f8~mv2.jpg" alt="" />
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_8a85cb0aac374001b4fac5366cb5acb2~mv2.jpg/v1/fill/w_319,h_213,q_90/563bec_8a85cb0aac374001b4fac5366cb5acb2~mv2.jpg" alt="" />
</div>

<div className="main-image-container">
  <div className="main-image-container-top">
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_106c05131bb34bdca76d3729497164ad~mv2.jpg/v1/fill/w_320,h_213,q_90/563bec_106c05131bb34bdca76d3729497164ad~mv2.jpg" alt="" />
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_d38c0b6d99984151af342ff93dbbeb2e~mv2.jpg/v1/fill/w_644,h_430,q_90/563bec_d38c0b6d99984151af342ff93dbbeb2e~mv2.jpg" alt="" />
  </div>
  <div className="main-image-container-bottom">
    <img className="img-2" src="https://static.wixstatic.com/media/563bec_cac2255eb9c94b43ba07af4ebdf5cb82~mv2.jpg/v1/fill/w_482,h_322,q_90/563bec_cac2255eb9c94b43ba07af4ebdf5cb82~mv2.jpg" alt="" />
  </div>
</div>

<div className="main-image-container">
  <div className="main-image-container-bottom">
    <img className="img-2" src="https://static.wixstatic.com/media/563bec_357ea3e303bd408a8d592af05a9b7d66~mv2.jpg/v1/fill/w_482,h_321,q_90/563bec_357ea3e303bd408a8d592af05a9b7d66~mv2.jpg" alt="" />
  </div>
  <div className="main-image-container-top">
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_3a9fb0006bac4740ab3fb7c85bd53d76~mv2.jpg/v1/fill/w_644,h_430,q_90/563bec_3a9fb0006bac4740ab3fb7c85bd53d76~mv2.jpg" alt="" />
    <img className="img-1" src="https://static.wixstatic.com/media/563bec_ddc76eff2d60489c8560a10f0e1da278~mv2.jpg/v1/fill/w_319,h_213,q_90/563bec_ddc76eff2d60489c8560a10f0e1da278~mv2.jpg" alt="" />
  </div>
</div>

<div className="middle-container">
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_fca0d78b238e46e6885796d6e2fdf161~mv2.jpg/v1/fill/w_320,h_213,q_90/563bec_fca0d78b238e46e6885796d6e2fdf161~mv2.jpg" alt="" />
  <img className="img-middle" src="https://static.wixstatic.com/media/563bec_0cca0fbb63b94b47bc01778444576ddd~mv2.jpg/v1/fill/w_971,h_648,q_90/563bec_0cca0fbb63b94b47bc01778444576ddd~mv2.jpg" alt="" />
</div>

      </div>

      <button className="scroll-button right" onClick={scrollRight}>
        &gt;
      </button>


    </div>
    <Footer />
    </>
  );
};

export default Gallery;
