import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

import HeroSection from '../components/HeroSection';
import BannerSection from '../components/BannerSection';
import HeroSection2 from '../components/HeroSection2';
import BannerSection2 from '../components/BannerSection2';
import HeroSection3 from '../components/Hero3';
import Footer from '../components/Footer';
import ScrollToTopScroller from '../elements/ScrollerToTop';

const HomePage = ({ restaurant }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={{ marginTop: '80px', backgroundColor: 'white' }}>
      <HeroSection />
      <BannerSection />
      <HeroSection2 />
      <BannerSection2 />
      <HeroSection3 />
      <ScrollToTopScroller />
      <Footer />

      {/* Sticky Icons Section */}
      <div className="sticky-icons">
  <a href="tel:3032804565" aria-label="Call us">
    <FontAwesomeIcon icon={faPhone} className="icon" />
  </a>
  <a
    href="https://www.google.com/maps/place/Mugi+Ramen+and+Poke/@39.9153896,-105.0021299,17z/data=!4m15!1m8!3m7!1s0x876c75c7ad7626c5:0x2a23b3b791f8f259!2s1181+W+120th+Ave+STE+100,+Westminster,+CO+80234!3b1!8m2!3d39.9153896!4d-104.999555!16s%2Fg%2F11gg3j1045!3m5!1s0x876c75d1c4b18a67:0x251d808d5c45ef01!8m2!3d39.9153619!4d-104.9994978!16s%2Fg%2F11fsm82xyw?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="View location on map"
  >
    <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
  </a>
  <a
    href="https://www.instagram.com/mugi_ramen_and_poke_?igsh=MTFuZmV0MDhreGs0Mw=="
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Visit our Instagram"
  >
    <FontAwesomeIcon icon={faInstagram} className="icon" />
  </a>
  <a
    href="https://www.facebook.com/profile.php?id=61568977040707"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Visit our Facebook page"
  >
    <FontAwesomeIcon icon={faFacebook} className="icon" />
  </a>
</div>

    </div>
  );
};

export default HomePage;
